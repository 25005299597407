<template>
  <div  class="panel-block">
    <div class="media">
      <div class="media-content">
        <h3>
          {{ group.name }}
        </h3>
      </div>
      <div class="media-right">
        <router-link :to="`/settings/user-groups/${group.id}`"
                     class="button is-light mr-1">
          <b-icon icon="edit" />
        </router-link>
        <button class="button is-light" @click="deleteItem" ><b-icon icon="trash-alt" /></button>
      </div>
    </div>
    <b-loading :active="loading" :is-full-page="true"/>
  </div>
</template>

<script>
export default {
  name: "UserGroupItem",
  props: ['group'],
  data() {
    return {
      loading: false,
    };
  },
  methods: {
    deleteItem() {
      this.$buefy.dialog.confirm({
        title: 'Удалить группу',
        message: 'Вы уверены, что хотите удалить группу?',
        confirmText: 'Удалить группу',
        type: 'is-danger',
        hasIcon: true,
        onConfirm: () => {
          this.loading = true;
          this.$store.dispatch('access_groups/deleteUserGroup', this.group.id).then(() => {
            this.$store.dispatch('access_groups/getGroupList', {}).then(() => {
              this.loading = false;
            })
          })
        }
      });
    }
  }
}
</script>

<style lang="scss">/*

*/</style>