<template>
 <div>
   <div class="block">
     <h2>
       {{ $t("group.groups_of_users") }}
     </h2>
   </div>
   <div v-if="loading" class="panel">
     <div class="panel-block">
       <b-skeleton :animated="true"></b-skeleton>
     </div>
     <div class="panel-block">
       <b-skeleton :animated="true"></b-skeleton>
     </div>
     <div class="panel-block">
       <b-skeleton :animated="true"></b-skeleton>
     </div>
     <div class="panel-block">
       <b-skeleton :animated="true"></b-skeleton>
     </div>
     <div class="panel-block">
       <b-skeleton :animated="true"></b-skeleton>
     </div>
   </div>
   <div v-else class="panel">
     <user-group-item :group="group" v-for="group in user_groups" :key="group.id" />
   </div>
   <a href="#" @click.prevent="createGroup()" class="button is-floating is-primary">
     <b-icon
         icon="plus"
         size="is-small" />
   </a>
 </div>
</template>

<script>
import {mapGetters} from "vuex";
import UserGroupItem from "../components/baseGroups/UserGroupItem";

export default {
  name: "UserGroupsList",
  components: {UserGroupItem},
  data() {
    return {
      loading: false
    };
  },
  computed: {
    ...mapGetters('access_groups', ['user_groups'])
  },
  methods: {
    loadUserGroups() {
      this.loading = true
      this.$store.dispatch('access_groups/getGroupList',{}).then(() => {
        this.loading = false;
      })
    },
    createGroup() {
      this.$router.push('/settings/user-groups/create')
    }
  },
  mounted() {
    this.loadUserGroups();
  }
}
</script>

<style scoped>
  h3 {
    font-weight: 700;
  }
  .is-floating {
    border-radius: 50%;
    position: fixed;
    bottom: 50px;
    right: 250px;
    height: 60px;
    width: 60px;
  }
  @media screen and (max-width: 768px)  {
    .is-floating {
      right: 50px;
    }
  }
  .user-wrapper {
    position: relative;
  }
  h2 {
    margin: 10px 0;
    font-size: 20px;
    font-weight: 700;
  }
</style>